import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import Main from './app/layout';
import { GuardedRoute } from './GuardedRoute';
import { CAMPAIGNS_READ, LOYALTY_READ, PRODUCTS_READ, SHOPS_READ } from './app/shared/utils/permissions';
import { ProductMenusPage } from './app/views/ProductMenusPage';
import HolidaysPage from './app/views/ExceptionDatePage';
import OutOfStockPage from './app/views/OutOfStockProductsPage';
import ProductTimelinePage from './app/views/ProductTimelinePage/ProductTimelinePage';
import { MapContextProvider } from './app/views/DeliveryZonesPageV2/MapContext';
import PaymentMethodsExceptionsPage from './app/views/PaymentMethodsExceptionsPage/PaymentMethodsExceptionsPage';

const HomePage = lazy(() => import('./app/views/HomePage'));
const CampaignsPage = lazy(() => import('./app/views/CampaignsPage'));
const ProfilePage = lazy(() => import('./app/views/ProfilePage'));
const CustomersPage = lazy(() => import('./app/views/CustomersPage'));
const OrdersPage = lazy(() => import('./app/views/OrdersPage/OrdersPage'));
const ProductsPage = lazy(() => import('./app/views/ProductsPage'));
const IngredientsPage = lazy(() => import('./app/views/IngredientsPage'));
const IngredientDosingsPage = lazy(() => import('./app/views/IngredientDosingsPage'));
const ProductsCategoriesPage = lazy(() => import('./app/views/ProductsCategoriesPage'));
const WarehouseItemsPage = lazy(() => import('./app/views/WarehouseItemsPage'));
const DeliveryZonePage = lazy(() => import('./app/views/DeliveryZonePage'));
const DeliveryZonesV2Page = lazy(() => import('./app/views/DeliveryZonesPageV2/DeliveryZonesPageV2'));
const RestaurantsPage = lazy(() => import('./app/views/RestaurantsPage'));
const SingleRestaurantPage = lazy(() => import('./app/views/SingleRestaurantPage'));

const AppRoutes: React.FC = () => {
  return (
    <Main>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/holidays" element={<HolidaysPage />} />
        <Route element={<GuardedRoute requiredPermissions={[LOYALTY_READ]} />}>
          <Route path="/customers" element={<CustomersPage />} />
        </Route>
        <Route element={<GuardedRoute requiredPermissions={[LOYALTY_READ]} />}>
          <Route path="/orders" element={<OrdersPage />} />
        </Route>
        <Route element={<GuardedRoute requiredPermissions={[CAMPAIGNS_READ]} />}>
          <Route path="/campaigns/*" element={<CampaignsPage />} />
        </Route>
        <Route element={<GuardedRoute requiredPermissions={[PRODUCTS_READ]} />}>
          <Route path="/products/*" element={<ProductsPage />} />
          <Route path="/upcoming-products/*" element={<ProductTimelinePage />} />
          <Route path="/ingredients/*" element={<IngredientsPage />} />
          <Route path="/ingredient-dosings/*" element={<IngredientDosingsPage />} />
          <Route path="/product-categories" element={<ProductsCategoriesPage />} />
          <Route path="/warehouse-items" element={<WarehouseItemsPage />} />
          <Route path="/product-menus/*" element={<ProductMenusPage />} />
          <Route path="/out-of-stock/*" element={<OutOfStockPage />} />
        </Route>
        <Route element={<GuardedRoute requiredPermissions={[SHOPS_READ]} />}>
          <Route path="/restaurants/*" element={<RestaurantsPage />} />
          <Route path="/restaurants/:id" element={<SingleRestaurantPage />} />
          <Route path="/zones/*" element={<DeliveryZonePage />} />
          <Route
            path="/zones-v2/*"
            element={
              <MapContextProvider>
                <DeliveryZonesV2Page />
              </MapContextProvider>
            }
          />
          <Route path="/payment-methods-exceptions" element={<PaymentMethodsExceptionsPage />} />
        </Route>
      </Routes>
    </Main>
  );
};

export default AppRoutes;
