import {
  CreateOutOfStockProductRequest,
  DosingDetailsDto,
  DosingUpdateDto,
  IngredientCategoriesDto,
  IngredientDto,
  MutationResponseDto,
  NewProductCategoryDto,
  NewProductSizeDto,
  OutOfStockProductDto,
  ProductCategoryDetailsDto,
  ProductDto,
  ProductMenuDto,
  ProductMenuOperationType,
  ProductPriceDto,
  ProductSizeDto,
  ProductsApiClient,
  S4DIngredientDto,
  S4DProductDto,
  ShopProductMenuDto,
  UserDetailsDTO,
  WarehouseItemDto,
  WarehouseItemCategoryDto,
  UpcomingProductDto,
  OutOfStockIngredientDto,
  CreateOutOfStockIngredientRequest,
  WarehouseItemUnitOverrideDto,
  VatDto,
} from '@kotipizzagroup/kotipizza-products-api-client';
import { getConfiguration } from '../shared/utils/configuration';
import Axios from 'axios';
import { getRequestHeaders, postToSlack } from './utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

let client: ProductsApiClient | null = null;

const getApiClient = (): ProductsApiClient => {
  if (client) return client;

  const baseUrl = getConfiguration<string>('productsService.root', '');

  const instance = Axios.create({
    timeout: 10000,
    transformResponse: [],
  });

  instance.interceptors.request.use(async (config) => {
    const headers = await getRequestHeaders();
    config.headers = headers;
    return config;
  });

  client = new ProductsApiClient(baseUrl, instance);
  return client;
};

/**
 * Products
 */

export const getAllProducts = async (): Promise<ProductDto[]> => {
  const client = getApiClient();
  const allProducts = await client.getAllProducts();
  return allProducts.map((product) => {
    if (product.isArchived) {
      return { ...product, name: `${product.name} [ARKISTOITU]` };
    }
    return product;
  });
};

const getAllS4DProducts = (): Promise<S4DProductDto[]> => {
  const client = getApiClient();
  return client.getS4DProducts();
};

export const getSingleProduct = async (productId: number): Promise<ProductDto> => {
  const client = getApiClient();
  const product = await client.getProductById(String(productId));
  if (product.isArchived) {
    return { ...product, name: `${product.name} [ARKISTOITU]` };
  }
  return product;
};

const getCategorySizes = (productCategoryId: number): Promise<ProductSizeDto[]> => {
  const client = getApiClient();
  return client.getCategorySizes(productCategoryId);
};

const getProductSizes = (productId: number): Promise<ProductSizeDto[]> => {
  const client = getApiClient();
  return client.getProductSizes(productId);
};

const getAllProductSizes = (): Promise<ProductSizeDto[]> => {
  const client = getApiClient();
  try {
    return client.getAllProductSizes();
  } catch (e) {
    console.error(e);
    return Promise.resolve([]);
  }
};

export const getProductPrices = (productId: number): Promise<ProductPriceDto[]> => {
  const client = getApiClient();
  return client.getProductPrices(productId);
};

export const getAllOutOfStockProducts = (): Promise<OutOfStockProductDto[]> => {
  const client = getApiClient();
  return client.outOfStockAll();
};

export const getAllOutOfStockIngredients = (): Promise<OutOfStockIngredientDto[]> => {
  const client = getApiClient();
  return client.ingredientAll();
};

const isOutOfStockIngredientRequest = (
  request: CreateOutOfStockProductRequest | CreateOutOfStockIngredientRequest
): request is CreateOutOfStockIngredientRequest => {
  return 'ingredientId' in request;
};

export const addOutOfStock = (
  request: CreateOutOfStockProductRequest | CreateOutOfStockIngredientRequest
): Promise<void> => {
  const client = getApiClient();
  if (isOutOfStockIngredientRequest(request)) {
    return client.ingredientPOST(request);
  }
  return client.outOfStockPOST(request);
};

export const removeOutOfStock = (
  request: CreateOutOfStockProductRequest | CreateOutOfStockIngredientRequest
): Promise<void> => {
  const client = getApiClient();
  if (isOutOfStockIngredientRequest(request)) {
    const { ingredientId, externalShopId } = request;
    return client.ingredientDELETE(ingredientId, externalShopId);
  }
  const { productId, externalShopId } = request;
  return client.outOfStockDELETE(productId, externalShopId);
};

export const updateProductPrice = (productId: number, prices: ProductPriceDto[]): Promise<MutationResponseDto> => {
  const client = getApiClient();
  return client.updateProductPrice(productId, prices);
};

export const upsertProduct = (product: ProductDto): Promise<MutationResponseDto> => {
  const client = getApiClient();
  if (!product.productId) {
    postToSlack(`${product.name} created in ${product.productCategoryName} category.`, 'production');
    return client.createProduct(product);
  }
  postToSlack(`${product.name} edited from Juuri.`, 'production');
  return client.updateProduct(product.productId, product);
};

type AddSizePayload = {
  categoryId: number;
  size: NewProductSizeDto;
};

export const addProductSize = (payload: AddSizePayload): Promise<void> => {
  const client = getApiClient();
  return client.addNewProductSize(payload.categoryId, payload.size);
};

export const addProductCategory = (category: NewProductCategoryDto): Promise<void> => {
  const client = getApiClient();
  return client.addNewProductCategory(category);
};

const getAllProductCategories = (): Promise<ProductCategoryDetailsDto[]> => {
  const client = getApiClient();
  return client.getAllProductCategoriesWithDetails();
};

const getAllUpcomingProducts = (): Promise<UpcomingProductDto[]> => {
  const client = getApiClient();
  return client.getUpcomingProducts();
};

/**
 * Ingredients
 */

const getSingleIngredient = (ingredientId: number): Promise<IngredientDto> => {
  const client = getApiClient();
  return client.getIngredientById(ingredientId);
};

export const upsertIngredient = (ingredientData: IngredientDto): Promise<MutationResponseDto> => {
  const client = getApiClient();
  if (!ingredientData.ingredientId) {
    return client.createIngredient(ingredientData);
  }
  return client.updateIngredient(ingredientData.ingredientId, ingredientData);
};

const getIngredientCategories = (): Promise<IngredientCategoriesDto> => {
  const client = getApiClient();
  return client.getIngredientCategories();
};

const getAllIngredients = async (includeArchived = true): Promise<IngredientDto[]> => {
  const client = getApiClient();
  const ingredients = await client.getAllIngredientsWithInactive();
  if (!includeArchived) {
    return ingredients.filter((ingredient) => !ingredient.isArchived);
  }
  return ingredients.map((ingredient) => {
    if (ingredient.isArchived) {
      return { ...ingredient, name: `${ingredient.name} [ARKISTOITU]` };
    }
    return ingredient;
  });
};

const getAllS4DIngredients = (): Promise<S4DIngredientDto[]> => {
  const client = getApiClient();
  return client.getS4DIngredients();
};

/**
 * Dosings
 */

export const updateIngredientDosings = (id: number, payload: DosingUpdateDto): Promise<MutationResponseDto> => {
  const client = getApiClient();
  return client.updateIngredientDosings(id, payload);
};

const getAllDosings = (): Promise<DosingDetailsDto> => {
  const client = getApiClient();
  return client.getAllDosings();
};

/**
 * Warehouse
 */

const getSingleWarehouseItem = (itemId: number | string): Promise<WarehouseItemDto> => {
  const client = getApiClient();
  return client.getWarehouseItem(String(itemId));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getSingleWarehouseItemUnitOverride = (itemId: number | string): Promise<WarehouseItemUnitOverrideDto> => {
  const client = getApiClient();
  return client.getWarehouseItemUnitOverrideOfWarehouseItem(String(itemId));
};

const getAllWarehouseItems = (): Promise<WarehouseItemDto[]> => {
  const client = getApiClient();
  return client.getWarehouseItems();
};

const getAllWarehouseItemCategories = (): Promise<WarehouseItemCategoryDto[]> => {
  const client = getApiClient();
  return client.getWarehouseItemCategories();
};

export const setWarehouseItemCategory = (itemId: string, itemCategoryId: number): Promise<void> => {
  const client = getApiClient();
  return client.setWarehouseItemCategory(itemId, itemCategoryId);
};

export const updateWarehouseItemUnitOverride = (
  itemId: string,
  unitOverride: WarehouseItemUnitOverrideDto
): Promise<void> => {
  const client = getApiClient();
  return client.updateWarehouseItemUnitOverride(itemId, unitOverride);
};

/**
 * Product menus
 */

export const upsertProductMenu = async (productMenu: ProductMenuDto): Promise<MutationResponseDto> => {
  const client = getApiClient();
  if (!productMenu.productMenuId) {
    return client.createProductMenu(productMenu);
  }
  await client.updateProductMenu(productMenu);
  return {
    id: 0,
  };
};

const getAllProductMenus = (): Promise<ProductMenuDto[]> => {
  const client = getApiClient();
  return client.getAllProductMenus();
};

export const getSingleProductMenu = (id: number): Promise<ProductMenuDto> => {
  const client = getApiClient();
  return client.getProductMenu(id);
};

export const getDefaultProductMenu = (): Promise<ProductMenuDto> => {
  const client = getApiClient();
  return client.getDefaultProductMenu();
};

export const getProductMenusForShop = (shopExternalId: string): Promise<ShopProductMenuDto[]> => {
  const client = getApiClient();
  return client.getShopProductMenusForShop(shopExternalId);
};

export const isProductInDefaultMenu = async (productId: number): Promise<boolean> => {
  try {
    const defaultMenu = await getDefaultProductMenu();
    if (!defaultMenu?.productIds) {
      throw new Error('Default menu products not found!');
    }

    return defaultMenu.productIds.some((pId) => pId === productId);
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const addProductToDefaultMenu = async (productId: number): Promise<MutationResponseDto> => {
  const defaultMenu = await getDefaultProductMenu();

  const client = getApiClient();
  return client.updateProductToMenu({
    productId,
    productMenuId: defaultMenu.productMenuId,
    operation: ProductMenuOperationType.Add,
  });
};

export const removeProductFromDefaultMenu = async (productId: number): Promise<MutationResponseDto> => {
  const defaultMenu = await getDefaultProductMenu();

  const client = getApiClient();
  return client.updateProductToMenu({
    productId,
    productMenuId: defaultMenu.productMenuId,
    operation: ProductMenuOperationType.Remove,
  });
};

/**
 * User
 */

export const getUserDetails = (): Promise<UserDetailsDTO> => {
  const client = getApiClient();
  return client.getUserDetails();
};

/**
 * Vat
 */

export const getAllVatRates = (): Promise<VatDto[]> => {
  const client = getApiClient();
  return client.getAllVatRates();
};

/**
 * Hooks
 */

type Options<T> = Parameters<typeof useQuery<T>>['2'];

const getDefaultOptions = <T>(): Options<T> => ({
  enabled: true,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const singleProductCacheKey = (id: number): [string, number] => ['product', id];

export const useProduct = (
  id: number,
  options = getDefaultOptions<ProductDto>()
): UseQueryResult<ProductDto, unknown> =>
  useQuery<ProductDto>(singleProductCacheKey(id), () => getSingleProduct(id), options);

export const useProductCategorySizes = (productCategoryId: number): UseQueryResult<ProductSizeDto[], unknown> => {
  return useQuery(['product-category-sizes', productCategoryId], () => getCategorySizes(productCategoryId));
};

export const useProductSizes = (
  productId: number,
  options = getDefaultOptions<ProductSizeDto[]>()
): UseQueryResult<ProductSizeDto[], unknown> => {
  return useQuery(['product-sizes', productId], () => getProductSizes(productId), options);
};

export interface ProductSize extends ProductSizeDto {
  productCategoryName: string;
  combinedName: string;
}

export const useAllProductSizes = (): UseQueryResult<ProductSize[], unknown> => {
  const { data: productCategories } = useAllProductCategories();

  return useQuery<ProductSize[]>(
    ['product-sizes-all'],
    async () => {
      const productSizes = await getAllProductSizes();

      return productSizes.map((size) => {
        const categoryName =
          productCategories?.find((category) => category.productCategoryId === size.productCategoryId)
            ?.productCategoryName || '';
        return {
          ...size,
          productCategoryName: categoryName,
          combinedName: `${categoryName}: ${size.name}`,
        };
      });
    },
    { enabled: Boolean(productCategories) }
  );
};

export const useProductPrices = (productId: number): UseQueryResult<ProductSizeDto[], unknown> => {
  return useQuery(['product-prices', productId], () => getProductPrices(productId));
};

export const useIngredient = (
  ingredientId: number,
  options = getDefaultOptions<IngredientDto>()
): UseQueryResult<IngredientDto, unknown> => {
  return useQuery(['ingredient', ingredientId], () => getSingleIngredient(ingredientId), options);
};

export const useIngredientCategories = (
  options = getDefaultOptions<IngredientCategoriesDto>()
): UseQueryResult<IngredientCategoriesDto, unknown> => {
  return useQuery(['ingredient-categories'], getIngredientCategories, options);
};

export const allIngredientsCacheKey = ['ingredients'];

export const useAllIngredients = (
  includeArchived = true,
  options = getDefaultOptions<IngredientDto[]>()
): UseQueryResult<IngredientDto[], unknown> => {
  return useQuery<IngredientDto[]>(allIngredientsCacheKey, () => getAllIngredients(includeArchived), options);
};

export const allDosingsCacheKey = ['dosings'];

export const useAllDosings = (
  options = getDefaultOptions<DosingDetailsDto>()
): UseQueryResult<DosingDetailsDto, unknown> => {
  return useQuery<DosingDetailsDto>(allDosingsCacheKey, getAllDosings, options);
};

export const allProductsCacheKey = ['products'];

export const useAllProducts = (options = getDefaultOptions<ProductDto[]>()): UseQueryResult<ProductDto[], unknown> =>
  useQuery<ProductDto[]>(allProductsCacheKey, getAllProducts, options);

export const useUpcomingProducts = (
  options = getDefaultOptions<UpcomingProductDto[]>()
): UseQueryResult<UpcomingProductDto[], unknown> =>
  useQuery<UpcomingProductDto[]>(['upcoming-products'], getAllUpcomingProducts, options);

export const useAllS4DProducts = (): UseQueryResult<S4DProductDto[], unknown> => {
  return useQuery<S4DProductDto[]>(['all-s4d-products'], getAllS4DProducts);
};

export const useAllS4DIngredients = (): UseQueryResult<S4DIngredientDto[], unknown> => {
  return useQuery<S4DIngredientDto[]>(['all-s4d-ingredients'], getAllS4DIngredients);
};

export const useWarehouseItem = (
  itemId: number | string,
  options = getDefaultOptions<WarehouseItemDto>()
): UseQueryResult<WarehouseItemDto, unknown> => {
  return useQuery<WarehouseItemDto>(['warehouse-item', itemId], () => getSingleWarehouseItem(itemId), options);
};

export const useWarehouseItems = (
  options = getDefaultOptions<WarehouseItemDto[]>()
): UseQueryResult<WarehouseItemDto[], unknown> =>
  useQuery<WarehouseItemDto[]>(['warehouse-items'], getAllWarehouseItems, options);

export const useWarehouseItemCategories = (
  options = getDefaultOptions<WarehouseItemCategoryDto[]>()
): UseQueryResult<WarehouseItemCategoryDto[], unknown> =>
  useQuery<WarehouseItemCategoryDto[]>(['warehouse-item-categories'], getAllWarehouseItemCategories, options);

export const useAllProductCategories = (
  options = getDefaultOptions<ProductCategoryDetailsDto[]>()
): UseQueryResult<ProductCategoryDetailsDto[], unknown> => {
  return useQuery<ProductCategoryDetailsDto[]>(['product-categories'], getAllProductCategories, options);
};

export const allProductMenusCacheKey = 'product-menus';

export const useAllProductMenus = (
  options = getDefaultOptions<ProductMenuDto[]>()
): UseQueryResult<ProductMenuDto[], unknown> => {
  return useQuery<ProductMenuDto[]>([allProductMenusCacheKey], getAllProductMenus, options);
};

export const defaultMenuCacheKey = 'default-menu';

export const useDefaultProductMenu = (
  options = getDefaultOptions<ProductMenuDto>()
): UseQueryResult<ProductMenuDto, unknown> => {
  return useQuery<ProductMenuDto>([defaultMenuCacheKey], getDefaultProductMenu, options);
};

export const useAllProductMenusForShop = (
  shopExternalId: string,
  options = getDefaultOptions<ShopProductMenuDto[]>()
): UseQueryResult<ShopProductMenuDto[], unknown> => {
  return useQuery<ShopProductMenuDto[]>([shopExternalId], () => getProductMenusForShop(shopExternalId), options);
};

export const allOutOfStockProductsCacheKey = 'out-of-stock-products';
export const useAllOutOfStockProducts = (): UseQueryResult<OutOfStockProductDto[], unknown> => {
  return useQuery([allOutOfStockProductsCacheKey], getAllOutOfStockProducts);
};

export const allOutOfStockIngredientsCacheKey = 'out-of-stock-ingredients';
export const useAllOutOfStockIngredients = (): UseQueryResult<OutOfStockIngredientDto[], unknown> => {
  return useQuery([allOutOfStockIngredientsCacheKey], getAllOutOfStockIngredients);
};

export const allVatRatesCacheKey = 'vat-rates';
export const useAllVatRates = (): UseQueryResult<VatDto[], unknown> => {
  return useQuery([allVatRatesCacheKey], getAllVatRates);
};
