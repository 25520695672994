import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { isUndefined } from 'lodash';

export interface FormCheckboxProps<T extends FieldValues> extends UseControllerProps<T> {
  label?: React.ReactNode;
  disabled?: boolean;
  infoText?: string;
}

export const FormCheckbox = <T extends FieldValues>(props: FormCheckboxProps<T>) => {
  const { label, control, name, infoText, disabled = false } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                // why? https://github.com/react-hook-form/react-hook-form/issues/459#issuecomment-549664020
                checked={isUndefined(field.value) || field.value === '' ? false : field.value}
                disabled={disabled}
                color="primary"
                {...field}
              />
            }
            label={label}
          />
        )}
      />
      {infoText && <Typography variant="body2">{infoText}</Typography>}
    </>
  );
};
