import { TopBar } from '../ProductsPage';
import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useAllRestaurants } from '../../apis/restaurants-service';
import dayjs from '../../shared/utils/dayJsRelativeTime';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  AdyenState,
  klarnaMerchantStatus,
  KlarnaState,
  paymentsApiQueryKeys,
  refetchRestaurantPaymentStatus,
  useAllShopPaymentStatus,
} from '../../apis/payments-api';
import { useQueryClient } from '@tanstack/react-query';
import { Restaurant } from '../../../../types/Restaurant';

type ErrorGroup = {
  [key: string]: string[];
};

type RestaurantCardProps = {
  shopExternalId: string;
  restaurantName: string;
  errors: ErrorGroup;
  updatedAt: string;
  refetchRestaurant: (shopExternalId: string) => void;
};

type ExtendedRestaurant = Restaurant & {
  injectOrdersToS4D: boolean;
};

const requiredAdyenPaymentMethods = ['googlepay', 'mobilepay', 'visa', 'mc', 'applepay', 'ebanking_FI', 'amex'];

const validateKlarna = (klarnaState: KlarnaState, restaurant: Restaurant): string[] => {
  const errors: string[] = [];

  if (!restaurant.hasWebsale) return errors;

  if (!klarnaState.currentStatus) {
    errors.push('Klarna merchant missing');
  } else {
    if (klarnaState.currentStatus !== klarnaMerchantStatus.PAYOUTS_ENABLED) {
      errors.push(`Status is ${klarnaMerchantStatus[klarnaState.currentStatus]}`);
    }
  }
  return errors;
};

const validateAdyen = (adyenState: AdyenState, isS4DInjectionEnabled: boolean): string[] => {
  const errors: string[] = [];

  const ecomMerchantKey = Object.keys(adyenState).find((key) => key.includes('ECOM'));

  if (!ecomMerchantKey) {
    errors.push('ECOM merchant missing');
  } else {
    const ecomMerchant = adyenState[ecomMerchantKey];

    const payoutSettings = ecomMerchant.payoutSettings;
    const payoutMissing = payoutSettings.length === 0 || !payoutSettings[0].enabled;

    if (payoutMissing) {
      errors.push('ECOM merchant payout settings missing');
    }

    const paymentMethodSettings = ecomMerchant.paymentMethodSettings;

    for (const requiredMethod of requiredAdyenPaymentMethods) {
      const method = paymentMethodSettings.find((method) => method.type === requiredMethod);

      if (!method || !method.enabled) {
        errors.push(`${requiredMethod} is missing or disabled`);
      }
    }
  }

  if (!isS4DInjectionEnabled) {
    // Do not check POS merchant if restaurant does not have S4D enabled. This will be removed when PFT-8583 is done
    return errors;
  }

  const posMerchantKey = Object.keys(adyenState).find((key) => key.includes('KASSA'));

  if (!posMerchantKey) {
    errors.push('POS merchant missing');
  } else {
    const posMerchant = adyenState[posMerchantKey];
    const payoutSettings = posMerchant.payoutSettings;
    const payoutMissing = payoutSettings.length === 0 || !payoutSettings[0].enabled;

    if (payoutMissing) {
      errors.push('POS merchant payout settings missing');
    }
  }

  return errors;
};

const RestaurantCard: React.FC<RestaurantCardProps> = ({
  shopExternalId,
  restaurantName,
  errors,
  updatedAt,
  refetchRestaurant,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <CardContent>
        <RestaurantDetails>
          <Typography variant="h3">
            {shopExternalId}
            <br />
            {restaurantName}
          </Typography>
          {Object.keys(errors).map((key) => {
            return (
              <ErrorGroup key={`error-group-${shopExternalId}-${key}`}>
                <b>{key}</b>
                <StyledUl>
                  {errors[key].map((error, index) => {
                    return <li key={`${shopExternalId}-${key}-${index}`}>{error}</li>;
                  })}
                </StyledUl>
              </ErrorGroup>
            );
          })}

          <LastUpdatedContainer>
            <RefreshButton onClick={() => refetchRestaurant(shopExternalId)}>
              <RefreshIcon />
            </RefreshButton>
            <UpdatedLabel>
              {t('Updated')} {dayjs().to(dayjs(updatedAt))}
            </UpdatedLabel>
          </LastUpdatedContainer>
        </RestaurantDetails>
      </CardContent>
    </StyledCard>
  );
};

const PaymentMethodsExceptionsPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: restaurants, isLoading: isLoadingRestaurants } = useAllRestaurants();

  const { data: shopPaymentProviderStatus = [], isLoading: isLoadingShopPaymentProviderStatus } =
    useAllShopPaymentStatus();

  const isLoading = isLoadingRestaurants || isLoadingShopPaymentProviderStatus;

  const paymentExpections = useMemo(() => {
    if (isLoading || !restaurants || !shopPaymentProviderStatus) return null;

    const entries = shopPaymentProviderStatus.map((shopPaymentStatus) => {
      const restaurant = restaurants.find(
        (restaurant) => restaurant.shopExternalId === shopPaymentStatus.shopExternalId
      );

      if (!restaurant) {
        const restaurantNotFoundError = `Unknown restaurant id ${shopPaymentStatus.shopExternalId}`;

        return {
          restaurantName: restaurantNotFoundError,
          updatedAt: shopPaymentStatus.updatedAt,
          shopExternalId: shopPaymentStatus.shopExternalId,
          errors: {
            Restaurant: [restaurantNotFoundError],
          },
        };
      }

      const { displayName, injectOrdersToS4D } = restaurant as ExtendedRestaurant;

      const klarnaErrors = validateKlarna(shopPaymentStatus.klarnaState, restaurant);
      const adyenErrors = validateAdyen(shopPaymentStatus.adyenState, injectOrdersToS4D);

      const errors: ErrorGroup = {};

      if (adyenErrors.length > 0) {
        errors.Adyen = adyenErrors;
      }

      if (klarnaErrors.length > 0) {
        errors.Klarna = klarnaErrors;
      }

      return {
        restaurantName: displayName,
        updatedAt: shopPaymentStatus.updatedAt,
        shopExternalId: shopPaymentStatus.shopExternalId,
        errors,
      };
    });

    return entries.filter((entry) => Object.keys(entry.errors).length > 0);
  }, [isLoading, restaurants, shopPaymentProviderStatus]);

  const validRestaurantsCount = useMemo(() => {
    if (!paymentExpections || !restaurants) return null;
    return shopPaymentProviderStatus.length - paymentExpections.length;
  }, [paymentExpections, restaurants, shopPaymentProviderStatus.length]);

  const refetchRestaurant = async (shopExternalId: string) => {
    await refetchRestaurantPaymentStatus(shopExternalId);
    await queryClient.invalidateQueries([paymentsApiQueryKeys.allShopPaymentStatus]);
  };

  if (!paymentExpections) {
    return null;
  }

  return (
    <>
      <TopBar>
        <Typography variant="h1">{t('Exceptions')}</Typography>
      </TopBar>
      <Typography variant="h2">
        {paymentExpections.length} {t('restaurants has expections in payment methods')}
      </Typography>
      <span>
        ✅ {validRestaurantsCount} {t('restaurants ok')}
      </span>
      <RestaurantCards>
        {paymentExpections.map((paymentExpection) => {
          return (
            <RestaurantCard
              refetchRestaurant={refetchRestaurant}
              updatedAt={paymentExpection.updatedAt}
              restaurantName={paymentExpection.restaurantName}
              shopExternalId={paymentExpection.shopExternalId}
              errors={paymentExpection.errors}
              key={`restaurant-card-${paymentExpection.shopExternalId}`}
            />
          );
        })}
      </RestaurantCards>
    </>
  );
};

const RestaurantCards = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  width: 25em;
`;

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const RestaurantDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpdatedLabel = styled.span`
  color: grey;
`;

const ErrorGroup = styled.div`
  margin-top: 16px;
`;

const LastUpdatedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
`;

const RefreshButton = styled.button`
  padding: 0;
  border: 0;
  margin-right: 4px;
  cursor: pointer;
  background: none;
`;

export default PaymentMethodsExceptionsPage;
